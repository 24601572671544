<template>
  <div class="menu-dropdown">
    <hub-button color="primary" variant="icon" :test-id="'action-list-button'" class="action-list-button" @click="show">
      <slot>
        <hub-icon name="dots-horizontal" :size="size"></hub-icon>
      </slot>
    </hub-button>
    <div v-if="menu" class="menu-dropdown-list-wrapper" :class="[direction]">
      <slot name="menu">
        <ul class="menu-dropdown-list">
          <li v-for="item of options" :key="item.key" class="menu-dropdown-list-item" @click="click(item.key)">
            <hub-icon :name="item.icon" :size="size" class="icon"></hub-icon><label>{{ item.label }}</label>
          </li>
        </ul>
      </slot>
    </div>
  </div>
</template>

<script>
import Button from './Button';
import Icon from './Icon';

export default {
  components: {
    'hub-button': Button,
    'hub-icon': Icon
  },
  props: {
    options: {
      type: Array,
      default: () => []
      // required: true,
      // validator: value => Array.isArray(value) && value.length
    },
    size: {
      type: String,
      default: 'md',
      validator(value) {
        return ['sm', 'md'].includes(value);
      }
    },
    direction: {
      type: String,
      default: 'right',
      validator(value) {
        return ['right', 'left'].includes(value);
      }
    }
  },
  emits: ['click'],
  data() {
    return {
      menu: null
    };
  },
  methods: {
    show() {
      if (this.menu) {
        return;
      }

      this.menu = true;

      setTimeout(() => document.addEventListener('click', this.hide), 0);
    },
    hide() {
      if (this.menu) {
        document.removeEventListener('click', this.hide);
        this.menu = null;
      }
    },
    click(action) {
      this.$emit('click', action);
    }
  }
};
</script>

<style lang="scss">
.menu-dropdown {
  display: inline-flex;
  position: relative;

  .menu-dropdown-list-wrapper {
    position: absolute;
    top: 100%;
    margin-top: 2px;
    z-index: 2;
    &.left {
      left: 0;
      right: unset;
    }
  }
  .menu-dropdown-list {
    max-width: 300px;
    width: auto;
    background: var(--theme-surface);
    border: 1px solid var(--theme-highlight);
    z-index: 1;
    border-radius: 3px;
  }

  .menu-dropdown-list-item {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0.5rem;
    cursor: pointer;

    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      font-weight: 500;
    }

    > * {
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 2rem;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--theme-highlight);
    }

    &:hover {
      background-color: var(--theme-highlight);
    }
  }
}
</style>
